* {-moz-box-sizing: border-box; box-sizing: border-box;}


body {
  background-color: #F9E591 !important;
  color: #150E56 !important;
}


.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
  background-color: #4ebfb4 !important;
  border-color: #4ebfb4 !important;
  color: white !important;
}

.btn-enabled {
  color: white !important;
}
.btn-disabled {
  color: white;
}


a:visited, ul.nav li a, ul.nav li a:visited {
//  color: #F9E591!important;
    color: #150E56 !important;
    
}

a:active, ul.nav li a:active {
  color: #F9E591!important;
}
a:hover, ul.nav li a:hover {
  color: rgb(0, 0, 0) !important;
  text-decoration: none !important;
}

a, ul.nav li.active a {
  // color: #F9E591!important;
  color: #150E56 !important;
  text-decoration: underline !important;
}



.page-content {
  color: #150E56;
  background-color: white ;
}

header, footer {
  padding: 1em;
  text-align: center;
  a, a:active, a:visited {
    color: white;
    text-decoration: none;
  }
}

header .header-navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}
header .header-navbar a {
  display: block;
  padding: 0.5rem 1rem;
}

footer {
  bottom: 0;
  left: 0;
  right: 0;
}

footer a {

  color: #150E56 !important;

}

.loading {
  height: 5em;
}
