@import url(https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&family=DM+Serif+Display:ital@0;1&family=Didact+Gothic&family=Lexend:wght@300;500&family=Source+Serif+Pro:wght@700&display=swap);
body {
  font-family: "Lexend" !important;
  font-weight: 300 !important;
}

h1,
h2,
h3,
h4 {
  font-family: "Source+Serif+Pro" !important;
}

.font-title {
  font-family: "Source+Serif+Pro" !important;
}

*{box-sizing:border-box}body{background-color:#f9e591 !important;color:#150e56 !important}.btn-primary,.btn-primary:hover,.btn-primary:active,.btn-primary:visited{background-color:#4ebfb4 !important;border-color:#4ebfb4 !important;color:#fff !important}.btn-enabled{color:#fff !important}.btn-disabled{color:#fff}a:visited,ul.nav li a,ul.nav li a:visited{color:#150e56 !important}a:active,ul.nav li a:active{color:#f9e591 !important}a:hover,ul.nav li a:hover{color:#000 !important;text-decoration:none !important}a,ul.nav li.active a{color:#150e56 !important;text-decoration:underline !important}.page-content{color:#150e56;background-color:#fff}header,footer{padding:1em;text-align:center}header a,header a:active,header a:visited,footer a,footer a:active,footer a:visited{color:#fff;text-decoration:none}header .header-navbar{display:flex;flex-direction:row;align-items:center;justify-items:center}header .header-navbar a{display:block;padding:.5rem 1rem}footer{bottom:0;left:0;right:0}footer a{color:#150e56 !important}.loading{height:5em}
