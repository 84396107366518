@import url("https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&family=DM+Serif+Display:ital@0;1&family=Didact+Gothic&family=Lexend:wght@300;500&family=Source+Serif+Pro:wght@700&display=swap");

body {
  font-family: "Lexend" !important;
  font-weight: 300 !important;
}

h1,
h2,
h3,
h4 {
  font-family: "Source+Serif+Pro" !important;
}

.font-title {
  font-family: "Source+Serif+Pro" !important;
}
